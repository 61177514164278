var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board"},[_c('v-stage',{attrs:{"config":{
      width: _vm.boardConfig.width,
      height: _vm.boardConfig.height,
    }},on:{"mousemove":_vm.handleMousemove}},[_c('v-layer',[_vm._l((new Array(_vm.boardConfig.gridSize)),function(_,index){return _c('v-group',{key:'vertical-line-' + index,attrs:{"config":{
          x: 0,
          y: index * _vm.boardConfig.cellWidth,
          id: ("vertical-" + index),
        }}},_vm._l((new Array(_vm.boardConfig.gridSize)),function(_,j){return _c('v-group',{key:'vertical-line-' + j,attrs:{"config":{
            x: j * _vm.boardConfig.cellHeight,
            y: 0,
            id: ("horizontal-" + j),
          }}},[(!!_vm.backImg)?_c('v-image',{attrs:{"config":{
              x: 0,
              y: 0,
              image: _vm.backImg,
              width: _vm.boardConfig.cellWidth,
              height: _vm.boardConfig.cellHeight,
            }}}):_vm._e()],1)}),1)}),_c('v-rect',{attrs:{"config":Object.assign({}, {x: 0,
          y: _vm.boardConfig.cellHeight * 2,
          width: 5,
          height: _vm.boardConfig.cellHeight},
          _vm.getPositionOfDoor(),
          {fill: '#f93c3c',
          shadowBlur: 10})}})],2),_c('v-layer',_vm._l((_vm.boardData),function(car,index){return _c('v-group',{key:index,attrs:{"config":{
          x: _vm.getPositionOfCar(car).x,
          y: _vm.getPositionOfCar(car).y,
          id: ("car-" + index),
          draggable: _vm.gameScore.success || _vm.gameScore.replaying ? false : true,
          dragBoundFunc: function (pos) {
            return car.isVertical
              ? {
                  x: this.absolutePosition().x,
                  y: _vm.getBound(pos, car, index),
                }
              : {
                  x: _vm.getBound(pos, car, index),
                  y: this.absolutePosition().y,
                };
          },
        },"draggable":true},on:{"dragend":function (e) { return _vm.handleDragEndCar(e, index); }}},[(!!_vm.getCarImage(car))?_c('v-image',{attrs:{"config":{
            x: 0,
            y: 0,
            image: _vm.getCarImage(car),
            width: car.isVertical
              ? _vm.boardConfig.cellWidth
              : car.cellCount * _vm.boardConfig.cellWidth,
            height: car.isVertical
              ? car.cellCount * _vm.boardConfig.cellHeight
              : _vm.boardConfig.cellHeight,
          }}}):_vm._e()],1)}),1),_c('v-layer',[(!!_vm.gameScore.success)?_c('v-text',{attrs:{"config":{
          x: 0,
          y: _vm.boardConfig.height / 2,
          width: _vm.boardConfig.width,
          fill: '#FFCAA1',
          stroke: 'red',
          strokeWidth: 2,
          align: 'center',
          text: 'Success! Great!',
          fontSize: 48,
          lineHeight: 1,
          fontWeight: 'bolder',
        }}}):_vm._e(),(
          !!_vm.mousePosition &&
          _vm.mousePosition.x &&
          _vm.mousePosition.y &&
          _vm.gameScore.replaying
        )?_c('v-image',{attrs:{"config":{
          x: _vm.mousePosition.x,
          y: _vm.mousePosition.y,
          image: _vm.pointerImg,
          width: 24,
          height: 28,
        }}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }