

















































/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { boardList } from "@/constant";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default Vue.extend({
  name: "Toolbar",
  components: {
    VueSlider,
  },
  computed: {
    boardData() {
      return this.$store.getters.boardData;
    },
    boardHistory() {
      return this.$store.getters.boardHistory;
    },
    boardConfig() {
      return this.$store.getters.boardConfig;
    },
    gameScore() {
      return this.$store.getters.gameScore;
    },
  },
  data() {
    return {
      value: 0,
      backAction: false,
      replayData: [],
    };
  },
  methods: {
    handleResetBoard() {
      const activeBoard = boardList.find(
        (board) => board.id === (this as any).boardConfig.id
      );
      if (activeBoard) {
        this.$store.commit("updateBoardData", activeBoard.data);
        if ((this as any).gameScore.success) {
          this.$store.commit("clearHistory");
          this.$store.commit("pushHistory", {
            type: "start",
            id: activeBoard.id,
            timestamp: new Date().getTime(),
          });
        } else {
          this.$store.commit("pushHistory", {
            type: "reset",
            boardData: activeBoard.data,
            timestamp: new Date().getTime(),
          });
        }
      }
    },
    handleBackBoard() {
      const moveHistory = (this as any).boardHistory.filter((e: any) =>
        ["piecemove", "reset", "back"].includes(e.type)
      );
      if (moveHistory.length < 2 + (this as any).gameScore.backLength * 2) {
        const activeBoard = boardList.find(
          (board) => board.id === (this as any).boardConfig.id
        );
        if (activeBoard) {
          this.$store.commit("updateBoardData", activeBoard.data);
          this.$store.commit("pushHistory", {
            type: "back",
            boardData: activeBoard.data,
            timestamp: new Date().getTime(),
          });
        }
      } else {
        const boardData =
          moveHistory[
            moveHistory.length - 2 - (this as any).gameScore.backLength * 2
          ].boardData;
        this.$store.commit("updateBoardData", boardData);
        this.$store.commit("pushHistory", {
          type: "back",
          boardData,
          timestamp: new Date().getTime(),
        });
      }
    },
    handleSliderEvents(e: any, value: number) {
      (this as any).t = value;
    },
    handleReplay() {
      const startTime = (this as any).boardHistory[0].timestamp;
      const endTime = (this as any).boardHistory[
        (this as any).boardHistory.length - 1
      ].timestamp;
      const timeHistory = (this as any).boardHistory.map((a: any) => ({
        ...a,
        time: Math.floor((a.timestamp - startTime) / 100) * 100,
      }));
      (this as any).replayData = [];
      this.$nextTick();
      (this as any).replayData = [
        ...new Array(Math.ceil((endTime - startTime) / 100) + 1),
      ]
        .map((_, index) => index * 100)
        .map((time) => {
          return timeHistory.find((e: any) => e.time === time) || { time };
        });

      (this as any).gameScore.success = false;
      (this as any).gameScore.replaying = true;
      const activeBoard = boardList.find(
        (board) => board.id === (this as any).boardConfig.id
      );
      if (activeBoard) {
        this.$store.commit("updateBoardData", activeBoard.data);
      }

      (this as any).t = 0;

      let replay = setInterval(() => {
        (this as any).t += 100;
        const currentItem = (this as any).replayData.find(
          (e: any) => e.time === (this as any).t
        );
        if (currentItem) {
          (this as any).value = currentItem.time;
          if (currentItem.boardData) {
            this.$store.commit("updateBoardData", currentItem.boardData);
          } else {
            this.$store.commit("updateMousePosition", {
              x: currentItem.x,
              y: currentItem.y,
            });
          }
        }
        if ((this as any).t > endTime - startTime) {
          clearInterval(replay);
          (this as any).t = 0;
          (this as any).gameScore.success = true;
          (this as any).gameScore.replaying = false;
          (this as any).replayData = [];
        }
      }, 100);
    },
  },
});
