




















/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { boardList } from "@/constant";

export default Vue.extend({
  name: "Score",
  computed: {
    user() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.boards = boardList;
  },
  data() {
    return {
      boards: [] as any[],
    };
  },
  methods: {
    handleChangeBoard(e: any) {
      const activeBoard = this.boards.find(
        (el: any) => el.id.toString() === e.target.value
      );
      this.$store.commit("selectActiveBoard", { ...activeBoard });
      this.$store.commit("clearHistory");
      this.$store.commit("pushHistory", {
        type: "start",
        id: e.target.value,
        timestamp: new Date().getTime(),
      });
    },
  },
});
