










/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Board from "@/components/Board.vue"; // @ is an alias to /src
import Score from "@/components/Score.vue";
import Toolbar from "@/components/Toolbar.vue";

export default Vue.extend({
  name: "Home",
  components: {
    Board,
    Score,
    Toolbar,
  },
});
